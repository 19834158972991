<template>
  <div>
    <Welcome
      :title="welcome.title"
      :subtitle="welcome.subtitle"
      :backgroundStyle="welcome.backgroundStyle"
      :show="true"
    />
    <div class="WhatWeDo">
      <h1>What We Do</h1>
      <p>
        Promoting environmental conservation, sustainable development, and human
        welfare and advancement by bringing basic education and awareness about
        both environment and life at large to Tanzanian people.
      </p>
      <img :src="Aboutimage" />
    </div>
    <div class="acboutSection">
      <Programs
        v-for="about in aboutUs"
        :key="about.id"
        :id="about.id"
        :Title="about.Title"
        :Description="about.Description"
        :Programimage="about.Programimage"
        :showToGetInvolved="true"
      />
    </div>
    <ActionStatementSection :text="action.text" :cta="action.cta" />
    <div class="accomodation" id="accomodation">
      <h1>Accomodation</h1>
      <img :src="Accomodationimage" />
      <p>
        We have a number of rooms to choose from at The Greenhouse, from small
        dorms to large dorms and private rooms with en-suite facilities. We can
        also host camping guests and provide bicycles for hire as well as offer
        transfer services to town or the airport..
      </p>
      <a href="https://thegreenhouse.co.tz" target="_blank">
        <button>VISIT THE GREENHOUSE HOSTEL</button>
      </a>
    </div>
  </div>
</template>

<script>
import Welcome from "../components/WelcomeSection";
import Programs from "../components/Programs";
import ActionStatementSection from "../components/ActionStatementSection";
export default {
  components: {
    Welcome,
    Programs,
    ActionStatementSection,
  },
  data() {
    return {
      welcome: {
        title: "About Us",
        subtitle: "All You Need To Know...",
        backgroundStyle: {
          backgroundImage: `url(${require("../assets/About/about-us-bg.png")})`,
        },
      },
      Aboutimage: require("../assets/Activities/tree-planting.png"),
      Accomodationimage: require("../assets/About/About-Accomodation.png"),
      action: {
        text: "Since June 2011 we have aimed to conserve the environment and support community development projects by providing aid and assistance to Tanzanians, by sharing knowledge and expertise.",
        cta: "JOIN US",
      },

      aboutUs: [
        {
          Title: "Why We Are Here",
          Description:
            "The Green Living Planet was set up to help educate people about the environment, supporting them to better understand how to preserve the nature around us. We support community development projects, providing aid and assistance to Tanzanians, not in the form of money, food, or clothing, but through shared knowledge and expertise.",
          Programimage: require("../assets/Activities/access-to-clean-water.png"),
        },
      ],
    };
  },
};
</script>

<style  scoped>
.WhatWeDo {
  text-align: center;
  margin: 100px auto 100px auto;
}
.WhatWeDo img,
.accomodation img {
  width: 100%;
}
.accomodation img {
  margin-top: 50px;
}
.accomodation h1 {
  margin: auto;
  max-width: 924px;
  font-size: 64px;
  font-weight: 600;
  line-height: 76px;
  text-align: center;
}
.accomodation p {
  margin: 20px auto;
  max-width: 1050px;
  color: #006233;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
}
.WhatWeDo h1 {
  color: #006233;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
}
.WhatWeDo p {
  padding: 0px 10px 50px 10px;
  margin: auto;
  color: #5a6275;
  max-width: 860px;
  font-family: Arial;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
}
button {
  padding: 20px;
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  background-color: #006233;
  margin-bottom: 200px;
}
@media screen and (max-width: 720px){
  .accomodation h1 {
  font-size: 34px;
}
.accomodation p {
  font-size: 20px;
  width: 90%;
  margin: auto;
  text-align: center;
}
button {
  font-size: 16px;
  margin-bottom: 100px;
}
}
</style>